<template>
  <div class="contents">
    <form action="">
      <!-- 연계시스템 관리_공통 설정 -->
      <div class="card shadow">
        <!-- 인증 정보 설정 -->
        <div class="card-header">
          <h3>인증정보 설정</h3>
        </div>
        <div class="card-body">
          <table class="table0 w500 tx_lf ml10">
            <caption>인증정보 설정</caption>
            <colgroup>
              <col>
              <col style="width:1200px">
            </colgroup>
            <tr>
              <th class="tx_lf h34"><i class="fas fa-caret-right fs14"></i> 사용자별 스크랩핑 사용여부 관리 설정</th>
              <td  colspan="3" class="tx_lf h34">
                <label class="switch">
                  <input type="checkbox" v-model="scrapUserManageYn">
                  <span class="slider round"></span>
                </label>
              </td>
              <button type="button" class="btn btn-primary ml15 mr20 w120" @click="putScrapUseMng(scrapUserManageYn)">저장</button>
            </tr>
          </table>
        </div>
        <!-- //인증 정보 설정 -->
      </div>

      <!-- 스트랩핑 관리_사용여부 관리  -->
      <div class="card shadow mt30">
        <div class="card-header">
          <h3>스크랩핑 설정</h3>
        </div>
        <div class="card-body">
          <!-- 스크랩핑 리스트 테이블 -->
          <div class="credit-table-block pl20 pt20 pb20 mb10">
            <base-select-box
                :class="selectBoxClass"
                :items="scrapGroupIds"
                v-model="scrapGroupIds"
                @toParent:selectValue="val => selectedGroupValue = val"
            />
            <button type="button" class="btn btn-darkgrey ml15 mr20 w120" @click="getScrapList(selectedGroupValue)">조회</button>
          </div>
          <!-- Button -->
          <div class="tx_rt pb10">
            <button type="button" class="btn btn-block btn-primary w60 mr5" @click="setScrapSwitch(true)">사용</button>
            <button type="button" class="btn btn-block btn-grey w70" @click="setScrapSwitch(false)">미사용</button>
          </div>
          <table class="table01">
            <caption>금융기관 표</caption>
            <colgroup>
              <col style="width:50px">
              <!-- <col style="width:1px"> -->
              <col>
              <col style="width:200px">
              <col style="width:200px">
            </colgroup>
            <thead>
            <tr>
              <!-- <th v-for="(item, index) in scrapLstTableItems.heads" :key="index" class="tx_cnt" v-html="item.col">{{ item.col }}</th> -->
              <th class="tx_cnt">
                <label><input type="checkbox" v-model="checkAll" @change="changeAllChecked($event.target.checked)"></label>

              </th>
              <th class="tx_cnt">스크랩핑 명</th>
              <th class="tx_cnt">대상구분</th>
              <th class="tx_cnt">사용여부</th>
            </tr>
            </thead>
            <tbody v-if="scrapLstTableItems.items.length != 0">
            <tr v-for="(item, index) in scrapLstTableItems.items" :key="index">
              <td class="tx_cnt">
                <label><input type="checkbox" :value="index" @change="changeChecked(index, item.checkBox)" :checked="item.checkBox"></label>
              </td>
              <td class="tx_cnt">{{ item.scrapName }}</td>
              <td class="tx_cnt">{{ item.targetKind }}</td>
              <td class="tx_cnt">
                <label class="switch">
                  <input type="checkbox" :value="item.scrapIdx" :checked="item.scrapEnable" @change="changeEnabled(index, item.scrapEnable)">
                  <span class="slider round"></span>
                </label>
              </td>
            </tr>
            </tbody>
            <tbody v-if="scrapLstTableItems.items.length == 0">
              <tr>
                <td class="tx_cnt" colspan="4">조회된 데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <!-- //스크랩핑 리스트 테이블 -->
        </div>
        <!-- Button -->
        <div class="btnwrap">
          <button type="button" class="btn btn-block btn-primary btn-lg w200" @click="putClientScrapList">저장</button>
        </div>
      </div>
      <!-- //스트랩핑 관리_사용여부 관리  -->
    </form>
  </div>
</template>
<script>

export default {
	name: 'PageCommonUseMng',
	data: () => ({
		// Variables					// TODO. asm_client_scrap
		prefix: "/api/linkedsys/common",
		scrapUserManageYn : false,		// 사용자별 사용여부 관리설정 변수
		// Components attrs
		selectBoxClass: 'form-control form-control-inline w200 mr5',
		scrapGroupIds : [{key: '', value: '선택',}],		// 스크랩핑 셀렉트 박스 목록
		selectedGroupValue : '',						// 스크랩핑 셀렉트 박스 선택값 변수
		// scrapList
		scrapLstTableItems:{
			heads:[
				// {col:'<label><input type="checkbox" v-model="checkAll" @change="changeAllChecked(checkAll)"></label>', style:'width:50px',},
				// // {col:'', style:'width:0.1px',},
				// {col:'스크랩핑 명', style:'',},
				// {col:'대상구분', style:'width:200px',},
				// {col:'사용여부', style:'width:200px',},
			],
			items:[]
		},
		checkAll : false,				// scrapList head checkbox
		//switchChk : '',				// 사용여부 스위치 set
		//updateScrapIdxLst : [],		// 업데이트 할 스크랩핑 scrapIdx목록
		//chkScrapIdx : '',			// chk항목의 idx 변수
	}),
	methods: {
		// 인증정보설정 조회
		scrapUseMngYn(){
			this.$cAxios.post(this.prefix + "/getScrapUserManageYn")
			.then((response) => {
				this.scrapUserManageYn = response.data;
				//this.$emit('scrapUserManageYn',response.data);

			}).catch((error) => {
				console.log(error.response.data);
			})

		},
		// 사용여부관리 설정 update
		putScrapUseMng(scrapUserManageYn){
			console.log("관리설정값", scrapUserManageYn);
			this.$cAxios.put(this.prefix + "/setScrapUserManageYn", "scrapUserManageYn="+scrapUserManageYn)
			.then((response) => {
				this.scrapUserManageYn = response.data;
				alert("사용자별 스크랩핑 사용여부 관리 설정이 변경되었습니다.");

			}).catch((error) => {
				console.log(error.response.data);
			})

		},
		// 스크랩핑설정  - selectBox
		searchScrapGroup(){
			this.$cAxios.post(this.prefix + "/getScrapGroupList")
			.then((response) => {
				//console.log("log datalist", response.data);

				if(response.data.length > 0){
					for(var i=0; i<response.data.length; i++){
						var groupData ={
							value : response.data[i].name,
							key : response.data[i].id
						};
						this.scrapGroupIds.push(groupData);
					}
				}

			}).catch((error) => {
				console.log(error.response.data);
			})

		},
		// 스크랩핑설정 - selectBox groupId 선택 후 스크랩핑명, 대상, 사용여부 조회
		getScrapList(selectedGroupId){
			// selectBox key가 있을 경우 검색 Go
			console.log("selected ",selectedGroupId);
			if(!selectedGroupId) return alert("스크랩핑 종류를 선택해 주세요");

			this.$cAxios.post(this.prefix + "/getClientScrapList", "groupId="+selectedGroupId)
			.then((response) => {
				//console.log("log ClientScrapList success", response.data);
				// 내역초기화
				this.checkAll = false;
				this.scrapLstTableItems.items = [];
				if (response.data.length > 0) {
					for (var i=0; i<response.data.length; i++) {
						//console.log("get data", response.data[i]);
						// scrapIdx, scrapName, scrapEnable, targetKind
						this.obj = {};
						this.obj.scrapIdx = response.data[i].scrapIdx;
						this.obj.scrapName = response.data[i].scrapName;
						this.obj.targetKind = response.data[i].targetKind;
						this.obj.scrapEnable = response.data[i].scrapEnable;
						this.obj.checkBox = false;

						this.scrapLstTableItems.items.push(this.obj);
						//console.log("push data", this.scrapLstTableItems.items);
					}
				}
			}).catch((error) => {
				console.log(error.response.data);
			})
		},
		// 전체선택,해제 이벤트
		changeAllChecked(checkAll){
			//console.log("changeAllChecked", checkAll);
			//checkAll
			for(var i=0; i<this.scrapLstTableItems.items.length; i++){
				this.scrapLstTableItems.items[i].checkBox = checkAll;
			}

		},
		// 체크박스 선택할때 updateList set과 로직처리.
		changeChecked(chkScrapIdx, chkStatus){
			// updateScrapIdxLst put
			this.scrapLstTableItems.items[chkScrapIdx].checkBox = !chkStatus;	// 현재 상태값 반대 넣어놓기

			// if(!chkStatus){	// checked 만 updateLst에 넣어주자  -> 안써도 될거같음 ..ㅋㅋ updateList가 따로 필요하다면 모르겠지만..
			// 	this.updateScrapIdxLst.push(chkScrapIdx);
			// }else{
			// 	// 제거
			// 	this.updateScrapIdxLst.splice(this.updateScrapIdxLst.indexOf(chkScrapIdx),1);
			// }
			// console.log("changeChecked ",this.updateScrapIdxLst);
		},
		// 사용여부 스위치 바꿀때 설정값 set
		changeEnabled(chkScrapIdx,chkStatus){
			this.scrapLstTableItems.items[chkScrapIdx].scrapEnable = !chkStatus;
		},
		// 체크박스 선택 후 사용,미사용 버튼 이벤트
		setScrapSwitch(switchChk){
			for(var i = 0; i<this.scrapLstTableItems.items.length; i++){
				if(this.scrapLstTableItems.items[i].checkBox == true){
					this.scrapLstTableItems.items[i].scrapEnable = switchChk;
					this.scrapLstTableItems.items[i].checkBox = false;
				}
			}
			// head checkbox 초기화
			this.checkAll = false;
		},
		// 사용여부 설정 후 저장
		putClientScrapList(){
			// 사용여부 리스트 this.scrapLstTableItems
			console.log("putClientScrapList ",this.scrapLstTableItems.items);

			this.header = {	'Content-Type': 'application/json;'	};
			this.$cAxios.put(this.prefix + "/setScrapLstEnable", this.scrapLstTableItems.items, this.header
			).then(() => {
				alert("스크랩핑 사용여부 설정이 변경되었습니다.");

			}).catch((error) => {
				alert(error.response.data);
				// 기존값 리로드
				this.getScrapList(this.selectedGroupValue);
			})
		},
	},
	watch: {

	},
	mounted() {
		// selectBox 호출
		this.searchScrapGroup();
	},
	created(){
		// 인증정보 설정 상태 조회
		this.scrapUseMngYn();
		//this.searchScrapGroup();

	}
}
</script>